import React, { useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { Csku, Typography } from "@commonsku/styles";
import { useTooltipAttributes } from "../../../../hooks";

type TColumn = typeof BASE_COLUMN_TYPES.Text;
const TextCell = ({
  column,
  row,
  className,
  onClick,
  textStyle = {},
  style = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform } = column;

  const { text, tooltipText, tooltipOptions } = useMemo<ColumnDataMap<TColumn>>(() => {
    const rawData = row.original[accessorKey];

    if (dataTransform) return dataTransform(rawData);

    const key = Object.keys(rawData);
    if (key.length !== 1)
      throw new Error(
        "TextCell can only handle one key if dataTransform is not provided",
      );
    return { text: rawData[key[0]] };
  }, [accessorKey, dataTransform, row.original]);

  const tooltipAttributes = useTooltipAttributes(tooltipText, REPORT_TOOLTIP_TARGET_ID, tooltipOptions);

  return (
    <Csku className={className} onClick={onClick}>
      <div style={style} {...tooltipAttributes}>
        <Typography.Span
          style={{
            display: "block",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
        >
          {text}
        </Typography.Span>
      </div>
    </Csku>
  );
};

export default TextCell;
